import { FunctionComponent } from 'react';
import { DefaultProps } from '../interface';

import { meaningData, profitData, howToUseData, howToCloseData } from './content';

export const CookiesPrivacySection: FunctionComponent<DefaultProps> = ({ prefix }) => {
  const firstSection = meaningData[prefix];
  const secondSection = profitData[prefix];
  const thirdSection = howToUseData[prefix];
  const forthSection = howToCloseData[prefix];

  return (
    <div className="privacy">
      <h2 className="mb-2">{firstSection.title}</h2>
      <div className="mb-2">{firstSection.data[0]}</div>

      <h2 className="mb-2">{secondSection.title}</h2>
      <div className="mb-2">
        <p>{secondSection.data[0]}</p>
      </div>

      <h2 className="mb-2">{thirdSection.title}</h2>
      <div className="mb-2">
        <p>{thirdSection.data[0]}</p>
        <p>{thirdSection.data[1]}</p>
        <p>{thirdSection.data[2]}</p>
        <p className="mb-0">{thirdSection.data[3]}</p>
        <p className="pl-2 mb-0">{thirdSection.data[4]}</p>
        <p className="pl-2">{thirdSection.data[5]}</p>

        <p className="mb-0">{thirdSection.data[6]}</p>
        <ol className="ml-2">
          <li className="mb-0">{thirdSection.data[7]}</li>
          <li className="mb-0">{thirdSection.data[8]}</li>
          <li className="mb-0">{thirdSection.data[9]}</li>
          <li className="mb-0">{thirdSection.data[10]}</li>
        </ol>
        <p className="mb-0">{thirdSection.data[11]}</p>
      </div>

      <h2 className="mb-2">{forthSection.title}</h2>
      <div className="mb-2">
        <p className="mb-2">{forthSection.data[0]}</p>
        <p className="mb-2">{forthSection.data[1]}</p>
      </div>
    </div>
  );
};

export default CookiesPrivacySection;
