export const entitle = {
  th: '',
  en: ''
};

type ContentType = {
  [k: string]: {
    title: React.ReactElement | JSX.Element | string;
    data: any[];
    table?: { header?: string[]; data: any[][] };
  };
};

export const meaningData: ContentType = {
  th: {
    title: '1.คุกกี้คืออะไร',
    data: [
      'คุกกี้ คือ ไฟล์ข้อความขนาดเล็กที่ถูกดาวน์โหลดไปยังอุปกรณ์คอมพิวเตอร์หรืออุปกรณ์มือถือของท่าน ซึ่งทำหน้าที่บันทึกข้อมูลและการตั้งค่าต่าง ๆ เช่น บันทึกข้อมูลการตั้งค่าภาษาในอุปกรณ์บนอุปกรณ์ของท่าน บันทึกสถานะการเข้าใช้งานในปัจจุบันของท่าน เพื่อช่วยให้ท่านสามารถเข้าใช้งานเว็บไซต์และ/หรือแอปพลิเคชัน (รวมเรียกว่า "บริการ") ได้อย่างต่อเนื่อง รวมถึงมีการรวบรวมข้อมูลเกี่ยวกับประวัติการเข้าใช้งานบริการที่ท่านชื่นชอบในรูปแบบไฟล์ โดยคุกกี้ไม่ได้ทำให้เกิดอันตรายต่ออุปกรณ์ของท่าน และเนื้อหาในคุกกี้จะถูกเรียกออกมาดูหรืออ่านได้โดยบริการที่สร้างคุกกี้ดังกล่าวเท่านั้น'
    ]
  },
  en: {
    title: '1. What are cookies?',
    data: [
      'A cookie is a small text file downloaded onto your computers or mobile telephone devices. The purpose of which is to store the various information and settings such as recording information of language setting in browser on your devices, recording your current access status so that you can access the website uninterrupted including collecting information on history of visited websites of your preference in the form of file. On this basis, a cookie does not pose a danger to your device and the content in the cookie may only be viewed or read via website which created it.'
    ]
  }
};

export const profitData: ContentType = {
  th: {
    title: '2.ประโยชน์ของคุกกี้',
    data: [
      'คุกกี้จะบอกให้บริษัท ไดรฟ์ฮับ จำกัด ("เรา" หรือ "บริษัท") ทราบว่าท่านเข้าใช้งานส่วนใดในบริการของบริษัท เพื่อที่บริษัท จะสามารถมอบประสบการณ์การใช้บริการที่ดีขึ้นและตรงกับความต้องการของท่านได้ นอกจากนี้ การบันทึกการตั้งค่าแรกของบริการด้วยคุกกี้จะช่วยให้ท่านเข้าถึงบริการด้วยค่าที่ตั้งไว้ทุกครั้งที่ใช้งาน ยกเว้นในกรณีที่คุกกี้ถูกลบซึ่งจะทำให้การตั้งค่าทุกอย่างจะกลับไปที่ค่าเริ่มต้น'
    ]
  },
  en: {
    title: '2. Benefit of cookies',
    data: [
      'A cookie will inform us which section of our website has been visited by you so that we can offer you better experience with regard to the use of website consistently with your need. In addition, using cookies to record the initial settings of the website will allow you to access the website with such initially determined settings on every occasion of use except where cookies are deleted. In the latter case, all the settings will be restored to the default settings.'
    ]
  }
};

export const howToUseData: ContentType = {
  th: {
    title: '3.การใช้งานคุกกี้',
    data: [
      'บริษัทใช้คุกกี้และเทคโนโลยีอื่น เช่น พิกเซลแท็ก (Pixel-tags) บนบริการของบริษัท (รวมเรียกว่า "บริการที่อ้างอิงนโยบายการใช้คุกกี้") ดังนั้น บริการใดก็ตามที่ท่านใช้เพื่อเข้าถึงบริการที่อ้างอิงนโยบายการใช้คุกกี้จะได้รับคุกกี้จากบริษัท',
      'เมื่อท่านเข้าถึงบริการที่อ้างอิงนโยบายการใช้คุกกี้ คุกกี้ของบริษัท จะถูกดาวน์โหลดไปยังอุปกรณ์ของท่านเพื่อเก็บข้อมูลเกี่ยวกับรูปแบบการใช้และประวัติการใช้บริการ ข้อมูลหรือบริการที่ท่านสนใจ รวมถึงหมายเลขอ้างอิงการเข้าใช้บริการล่าสุดของท่าน เป็นต้น',
      <>
        นอกจากนั้น บริษัทมีการใช้งานคุกกี้ร่วมกับเทคโนโลยีประเภทพิกเซลแท็ก
        เพื่อทราบถึงรูปแบบการใช้และประวัติการใช้บริการ ข้อมูลหรือบริการที่ท่านสนใจ และนำไปวิเคราะห์
        เพื่อพัฒนาการให้บริการ แสดงเนื้อหา โฆษณา หรือประชาสัมพันธ์กิจกรรมที่เหมาะสมรวมถึงการบริการต่าง ๆ
        ที่ตรงกับความสนใจของท่าน เพื่อเพิ่มความพึงพอใจให้แก่ท่านได้มากยิ่งขึ้น อย่างไรก็ตาม บริษัท
        จะใช้งานคุกกี้ภายใต้รายละเอียดที่ระบุในคำประกาศเกี่ยวกับความเป็นส่วนตัว ซึ่งท่านสามารถศึกษาข้อมูลเพิ่มเติมได้จาก{' '}
        <a href="/th/privacy">https://www.drivehub.com/th/privacy</a>
      </>,
      'สำหรับประเภทคุกกี้ที่บริษัท ใช้งาน สามารถแบ่งประเภทได้ดังนี้',
      <>
        <p className="mb-0">
          <u>3.1 คุกกี้เพื่อการให้บริการ</u>
        </p>
        คุกกี้เพื่อการให้บริการจะช่วยให้ท่านสามารถใช้บริการ และ/หรือเข้าถึงฟังก์ชันต่าง ๆ ได้ เช่น
        คุกกี้ที่จัดการเรื่องการเข้าสู่ระบบ (Login) และสถานะการเข้าสู่ระบบของท่าน
      </>,
      <>
        <p className="mb-0">
          <u>3.2 คุกกี้ที่ช่วยเสริมประสิทธิภาพในการใช้บริการ</u>
        </p>
        คุกกี้ที่ช่วยเสริมประสิทธิภาพในการใช้บริการจะช่วยอำนวยความสะดวกแก่ท่านในการใช้บริการ รวมทั้งช่วยให้บริษัท
        รู้จักท่านมากยิ่งขึ้น โดยบริษัท จะใช้ข้อมูลดังกล่าวนี้ในการนำเสนอและพัฒนาสินค้าและ/หรือบริการของบริษัท
        ให้ตรงกับความสนใจของท่านต่อไป
      </>,
      'ตัวอย่างการอำนวยความสะดวกแก่ท่านในการใช้บริการด้วยคุกกี้ประเภทนี้ คือ',
      'ช่วยจำสถานะของการใช้บริการ เช่น ภาษาที่เลือกใช้',
      'ช่วยจำคำตอบที่ท่านเคยตอบไปแล้ว ซึ่งจะช่วยให้ท่านไม่ต้องตอบคำถามเดิมซ้ำอีก',
      'ให้บริการ LiveChat บนเว็บไซต์ของบริษัท',
      'ให้บริการฟังก์ชัน Social Media Sharing ซึ่งจะช่วยให้ท่านสามารถแบ่งปันเนื้อหาที่สนใจบนเครือข่ายสังคมออนไลน์ (Social Network)',
      'ทั้งนี้ เพื่อการให้บริการและเพื่ออำนวยความสะดวกแก่ท่านในการใช้บริการตามตัวอย่างข้างต้นบริษัท มีการใช้งานโปรแกรมเสริม (Plugin) และบริการของบุคคลที่สาม เช่น Google Analytics, Facebook Analytics, Facebook, Line และ Twitter'
    ]
  },
  en: {
    title: '3. Cookie Usage',
    data: [
      'The company utilizes cookies and other technologies such as pixel tags on its services (collectively referred to as the "Cookie Policy-referenced services"). As a result, any service you use to access the Cookie Policy-referenced services will receive cookies from the company.',
      `When you access the Cookie Policy-referenced services, the company's cookies will be downloaded onto your device to gather information about usage patterns and history of service usage. This includes data or services of interest to you, along with your most recent service access reference numbers, and more.`,
      <>
        Furthermore, the company employs cookies in conjunction with pixel tag technologies to understand usage patterns
        and history of service usage, data or services of interest to you. This information is then analyzed to enhance
        service delivery, display content, advertisements, or relevant event promotions, as well as provide various
        services that align with your interests, ultimately aiming to increase your satisfaction. However, the company
        will use cookies according to the details specified in the privacy statement, which you can learn more about at{' '}
        <a href="/en/privacy">https://www.drivehub.com/en/privacy</a>
      </>,
      'For the types of cookies that the company uses, they can be categorized as follows:',
      <>
        <p className="mb-0">
          <u>3.1 Service Cookies</u>
        </p>
        Service cookies are used to enable you to use the services and/or access various functions. For example, cookies
        that manage login and your login status.
      </>,
      <>
        <p className="mb-0">
          <u>3.2 Performance-Enhancing Cookies</u>
        </p>
        Performance-enhancing cookies help improve your experience when using the services. They provide convenience and
        help the company become better acquainted with you. The information gathered through these cookies is used by
        the company to present and develop products and/or services that align with your interests moving forward.
      </>,
      'Examples of how convenience is provided to you through this type of cookie are:',
      `Remembering Service Status: Cookies help remember your service preferences, such as the language you've chosen.`,
      `Remembering Previous Answers: Cookies store previous responses you've given. This helps you avoid answering the same questions repeatedly.`,
      `LiveChat Services: Cookies enable the provision of LiveChat services on the company's website.`,
      `Social Media Sharing Function: Cookies enable social media sharing functions, allowing you to easily share content you're interested in on online social networks.`,
      'In pursuit of providing seamless services and convenience to you, the company employs supplementary programs (plugins) and services from third parties, such as Google Analytics, Facebook Analytics, Facebook, Line, and Twitter, as exemplified above.'
    ]
  }
};

export const howToCloseData: ContentType = {
  th: {
    title: '4.วิธีปิดการทำงานของคุกกี้',
    data: [
      <>
        ท่านสามารถปิดการทำงานของคุกกี้ได้ โดยการตั้งค่าเบราว์เซอร์ของท่าน
        และตั้งค่าความเป็นส่วนตัวเพื่อระงับการรวบรวมข้อมูลโดยคุกกี้ในอนาคต (รายละเอียดเพิ่มเติมสามารถศึกษาได้จาก{' '}
        <a href="https://www.aboutcookies.org/">AboutCookies.org</a>)
      </>,
      'อย่างไรก็ตาม บริการบางอย่างบนเว็บไซต์ของบริษัท จำเป็นต้องมีการใช้คุกกี้ หากท่านปิดการทำงานคุกกี้อาจทำให้ท่านใช้งานฟังก์ชันบางอย่างหรือทั้งหมดของบริการดังกล่าวได้อย่างไม่ราบรื่น'
    ]
  },
  en: {
    title: '4. Settings and disabling of Cookies',
    data: [
      <>
        You may choose either to enable all types of Cookies or to disable all types of Cookies according to your needs.
      </>,
      'When you choose to disable the Cookies through your browser and privacy settings to prevent the Cookies from collecting information in the future (more information may be studied at AboutCookies.org), you may continue to use the websites and/or applications of the Stock Exchange Group. However, the disabling of Cookies may cause your use of certain or all the functions of the websites and/or applications of the Stock Exchange Group not to be smooth.'
    ]
  }
};
export default {};
